import { Tab } from "@mui/material";
import { useState } from "react";
import { MyTabs } from "./style";
import MenuSectionsTab from "./MenuSectionsTab";
import ContentTab from "./ContentTab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`content-tabpanel-${index}`}
            aria-labelledby={`content-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

const tabLabel = (text) => {
    return <span className="labelText">{text}</span>;
};

const labelProps = (index) => {
    return {
        id: `content-tab-${index}`,
        "aria-controls": `content-tabpanel-${index}`,
        // key: index,
        value: index
    };
};

export default function Content() {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <MyTabs
                value={value}
                onChange={handleChange}
                aria-label="Content Tab"
            >
                <Tab key={0} label={tabLabel("Content")} {...labelProps(0)} />
                <Tab
                    key={1}
                    label={tabLabel("Menu Sections")}
                    {...labelProps(1)}
                />
            </MyTabs>

            <div>
                <TabPanel value={value} index={0}>
                    <ContentTab />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MenuSectionsTab />
                </TabPanel>
            </div>
        </>
    );
}
