import { Tab } from "@mui/material";
import { MyTabs } from "./style";
import PaperLayout from "./PaperLayout";
import PageLayout from "./PageLayout";
import { usePrinterContext } from "../../../../context/printer";
import { LAYOUT_TAB_INDEX } from "../../../../const/general";
import Icon from "../../../UI/Icon";
import { Box } from "@mui/system";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`layout-tabpanel-${index}`}
            aria-labelledby={`layout-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

const tabLabel = (text) => {
    return <span className="labelText">{text}</span>;
};

const labelProps = (index) => {
    return {
        id: `layout-tab-${index}`,
        "aria-controls": `layout-tabpanel-${index}`,
        // key: index,
        value: index
    };
};

export default function Layout({ blockData }) {
    const { layoutTabIndex, setLayoutTabIndex } = usePrinterContext();

    const { PAPER_LAYOUT, PAGE_LAYOUT } = LAYOUT_TAB_INDEX;

    const handleChange = (event, newValue) => {
        setLayoutTabIndex(newValue);
    };

    const pageIndex = blockData?.pageIndex;

    return (
        <>
            <MyTabs
                value={layoutTabIndex}
                onChange={handleChange}
                aria-label="Layout Tab"
            >
                <Tab
                    key={PAPER_LAYOUT}
                    label={tabLabel("Paper Layout")}
                    {...labelProps(PAPER_LAYOUT)}
                />
                <Tab
                    key={PAGE_LAYOUT}
                    label={tabLabel("Page Layout")}
                    {...labelProps(PAGE_LAYOUT)}
                />
            </MyTabs>

            <Box>
                <TabPanel value={layoutTabIndex} index={PAPER_LAYOUT}>
                    <PaperLayout />
                </TabPanel>
                <TabPanel value={layoutTabIndex} index={PAGE_LAYOUT}>
                    {pageIndex || pageIndex === 0 ? (
                        <PageLayout pageIndex={pageIndex} />
                    ) : (
                        <Box className="noBlockStyle layoutTab">
                            <Icon name="fileIcon" />
                            <p>
                                Select a page in your menu or click edit button
                                on top right above a page in canvas to edit
                                layout.
                            </p>
                        </Box>
                    )}
                </TabPanel>
            </Box>
        </>
    );
}
