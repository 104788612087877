import { Box } from "@mui/system";
import Icon from "../../UI/Icon";
import { Root } from "./style";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { usePrinterContext } from "../../../context/printer";
import ImageBlock from "./EditorBlock/ImageBlock";
import DividerBlock from "./EditorBlock/DividerBlock";
import SectionBlock from "./EditorBlock/SectionBlock";
import { generateId } from "../../../utils/uuid";
import MirrorTextBlock from "./EditorBlock/MirrorTextBlock";
import BlockData from "../../../const/BlockData";
import SortableItem from "./SortableItem";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import {
    DEFAULT_ROW_COL_SPACE,
    EXTRA_PAGE_MARGIN,
    LAYOUT_TAB_INDEX,
    SIDEBAR_TAB_INDEX
} from "../../../const/general";
import CustomTooltip from "../../UI/CustomTooltip/CustomTooltip";
import {
    customEvents,
    customPageEvents,
    customRowEvents,
    customItemEvents
} from "../../../utils/customEvents";
import {
    isBlobUrl,
    percentToPixel,
    pixelToPercent
} from "../../../utils/common";
import { draggedStyle, useDraggableInPortal } from "../../../utils/draggingFun";
import { loadImage } from "../../../utils/loadImage";

export default function PageEditor({
    onMoveUp,
    onMoveDown,
    onDeletePage,
    onAddPage,
    pageId,
    isFirst,
    isLast,
    totalPage,
    pageContent,
    pageIndex,
    handleBlockClick,
    focusId,
    setFocusId,
    setHandleOverflowDrop,
    singlePage,
    pageEdit,
    setGridColumnPopup,
    setPIndex,
    setCountImageLoaded,
    overflowColumn,
    setHandleRowRemove,
    removeRowConfirm,
    setFirstRowAdd,
    topBarRef,
    pageEditorWrapRef
}) {
    const [disabled, setDisabled] = useState({
        delete: false,
        downward: false,
        upward: false
    });

    useEffect(() => {
        setDisabled((prevDisabled) => ({
            ...prevDisabled,
            delete: totalPage <= 1,
            downward: isLast,
            upward: isFirst
        }));
    }, [pageId, isFirst, isLast, totalPage]);

    const {
        layoutState,
        draggedItem,
        pageBlocks,
        setPageBlocks,
        sectionItems,
        showPreview,
        setSectionList,
        zoomLevel,
        overflowContent,
        setTabIndex,
        setLayoutTabIndex
    } = usePrinterContext();

    const { LAYOUT, CONTENT, STYLES } = SIDEBAR_TAB_INDEX;
    const { PAGE_LAYOUT } = LAYOUT_TAB_INDEX;

    const { pageLayout } = layoutState;
    const paperSize = pageLayout.paperSize;
    const orientation = pageLayout.activeOrientation;

    const toEdge = pageBlocks[pageIndex]?.page_properties?.printToEdge;
    const gridMode =
        pageBlocks[pageIndex]?.page_properties?.layout?.gridMode?.active;
    const marginValue =
        pageBlocks[pageIndex]?.page_properties?.marginValue +
        (!toEdge && !gridMode ? EXTRA_PAGE_MARGIN : 0);
    const borderTopSpace = marginValue > 0 ? marginValue / 2 : 0;
    const borderBottomSpace = marginValue > 0 ? marginValue / 2 : 0;
    const borderTop =
        pageBlocks[pageIndex]?.page_properties?.border?.position?.top;
    const borderStyle = pageBlocks[pageIndex]?.page_properties?.border?.style;
    const borderTopRadius =
        pageBlocks[pageIndex]?.page_properties?.border?.radius?.top;
    const borderLeftRadius =
        pageBlocks[pageIndex]?.page_properties?.border?.radius?.left;
    const borderRightRadius =
        pageBlocks[pageIndex]?.page_properties?.border?.radius?.right;
    const borderBottomRadius =
        pageBlocks[pageIndex]?.page_properties?.border?.radius?.bottom;
    const borderRight =
        pageBlocks[pageIndex]?.page_properties?.border?.position?.right;
    const borderBottom =
        pageBlocks[pageIndex]?.page_properties?.border?.position?.bottom;
    const borderLeft =
        pageBlocks[pageIndex]?.page_properties?.border?.position?.left;
    const borderWidth = pageBlocks[pageIndex]?.page_properties?.border?.width;
    const borderColor =
        pageBlocks[pageIndex]?.page_properties?.border?.color || "#000000";
    const columnCount =
        pageBlocks[pageIndex]?.page_properties?.layout?.columnCount;
    const totalColumn = parseFloat(columnCount);
    const columnGap =
        pageBlocks[pageIndex]?.page_properties?.layout?.columnSpace;
    const mirrorMode =
        pageBlocks[pageIndex]?.page_properties?.layout?.mirrorMode;
    const gridColumn = parseFloat(
        pageBlocks[pageIndex]?.page_properties?.layout?.gridMode?.column
    );
    const backgroundUrl =
        pageBlocks[pageIndex]?.page_properties?.background?.url;

    const [bgImageSrc, setBgImageSrc] = useState(backgroundUrl);

    useEffect(() => {
        if (backgroundUrl) {
            if (!isBlobUrl(backgroundUrl)) {
                loadImage(
                    backgroundUrl,
                    (image) => {
                        setBgImageSrc(image);
                    },
                    (error) => {
                        console.log(error);
                        setBgImageSrc(backgroundUrl);
                    }
                );
            } else {
                setBgImageSrc(backgroundUrl);
            }
        } else {
            setBgImageSrc(backgroundUrl);
        }
    }, [backgroundUrl]);

    const backgroundSize = backgroundUrl
        ? pageBlocks[pageIndex]?.page_properties?.background?.size
        : "";
    const backgroundCover =
        pageBlocks[pageIndex]?.page_properties?.background?.cover;
    const backgroundPos =
        pageBlocks[pageIndex]?.page_properties?.background?.location;
    const backgroundColor =
        pageBlocks[pageIndex]?.page_properties?.background?.color;
    const backgroundTrans =
        pageBlocks[pageIndex]?.page_properties?.background?.transparency / 100;

    const [activeColIndex, setActiveColIndex] = useState(null);

    const { BLOCK_SUB_TYPE, BLOCKSTYLES, BLOCKTYPE } = BlockData();

    const gridRowWrap = useRef();

    const handleDragEnter = (e, pageIndex, rowIndex, colIndex) => {
        setActiveColIndex([pageIndex, rowIndex, colIndex].toString());
    };

    const handleDragLeave = (e) => {
        setActiveColIndex(null);
    };

    const handleDrop = (e, pageIndex, rowIndex, colIndex) => {
        setActiveColIndex(null);
        if (!draggedItem) {
            return;
        }

        if (overflowColumn[pageIndex][rowIndex][colIndex]) {
            setHandleOverflowDrop(true);
            setActiveColIndex(null);
            return;
        }

        e.preventDefault();
        setActiveColIndex(null);
        const uniqueId = generateId();

        let customType = "";
        let customData = {};
        let blockType = "";

        if (draggedItem === BLOCKTYPE.SECTION_ITEMS) {
            setSectionList((prevSectionList) =>
                prevSectionList.filter((item) => item._id !== sectionItems._id)
            );
        }

        // Customize type and data based on the provided ID
        switch (draggedItem) {
            case BLOCKTYPE.RESTAURANT_LOGO:
                customType = BLOCK_SUB_TYPE.IMAGE;
                customData = BLOCKSTYLES.RESTAURANT_LOGO;
                blockType = draggedItem;
                break;
            case BLOCKTYPE.MENU_TITLE:
                customType = BLOCK_SUB_TYPE.TEXT;
                customData = BLOCKSTYLES.MENU_TITLE;
                blockType = draggedItem;
                break;
            case BLOCKTYPE.MENU_IMAGE:
                customType = BLOCK_SUB_TYPE.IMAGE;
                customData = BLOCKSTYLES.MENU_IMAGE;
                blockType = draggedItem;
                break;
            case BLOCKTYPE.CUSTOM_PARAGRAPH:
                customType = BLOCK_SUB_TYPE.EDITABLE_TEXT;
                customData = BLOCKSTYLES.CUSTOM_PARAGRAPH;
                blockType = draggedItem;
                break;
            case BLOCKTYPE.CUSTOM_TITLE:
                customType = BLOCK_SUB_TYPE.EDITABLE_TEXT;
                customData = BLOCKSTYLES.CUSTOM_TITLE;
                blockType = draggedItem;
                break;
            case BLOCKTYPE.MENU_HEADER:
                customType = BLOCK_SUB_TYPE.TEXT;
                customData = BLOCKSTYLES.MENU_HEADER;
                blockType = draggedItem;
                break;
            case BLOCKTYPE.MENU_FOOTER:
                customType = BLOCK_SUB_TYPE.TEXT;
                customData = BLOCKSTYLES.MENU_FOOTER;
                blockType = draggedItem;
                break;
            case BLOCKTYPE.DIVIDER:
                customType = BLOCK_SUB_TYPE.DIVIDER;
                customData = BLOCKSTYLES.DIVIDER;
                blockType = draggedItem;
                break;
            case BLOCKTYPE.SECTION_ITEMS:
                customType = BLOCK_SUB_TYPE.SECTION;
                customData = BLOCKSTYLES.SECTION_ITEMS;
                blockType = draggedItem;
                break;

            default:
                return null;
        }

        // Add the new block with custom type and data
        const updatedEditors = [...pageBlocks];
        updatedEditors[pageIndex].row[rowIndex].column[colIndex].blocks = [
            ...updatedEditors[pageIndex].row[rowIndex].column[colIndex].blocks,
            {
                type: customType,
                data: customData,
                blockType: blockType,
                id: uniqueId
            }
        ];

        const lengthOfDroppedBlock =
            updatedEditors[pageIndex].row[rowIndex].column[colIndex].blocks
                .length - 1;
        setFocusId(
            [pageIndex, rowIndex, colIndex, lengthOfDroppedBlock].toString()
        );
        customEvents(
            pageIndex,
            rowIndex,
            colIndex,
            lengthOfDroppedBlock,
            blockType
        );
        setPageBlocks(updatedEditors);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const removeBlock = (rowIndex, columnIndex, blockIndex, pageInd) => {
        const updateBlocks = [...pageBlocks];
        if (
            updateBlocks[pageInd]?.row[rowIndex]?.column[columnIndex]?.blocks[
                blockIndex
            ].type === "section"
        ) {
            const removeItems =
                updateBlocks[pageInd]?.row[rowIndex]?.column[columnIndex]
                    ?.blocks[blockIndex]?.data?.items;
            setSectionList((prevSectionList) => [
                ...prevSectionList,
                removeItems
            ]);
        }

        updateBlocks[pageInd]?.row[rowIndex]?.column[
            columnIndex
        ]?.blocks?.splice(blockIndex, 1);

        setPageBlocks(updateBlocks);

        // Clear Styles Tab
        setTimeout(() => {
            handleBlockClick("");
            customEvents("");
            customItemEvents("");
        }, 0);
    };

    const removeRow = (rowIndex, pageInd) => {
        const hasBlocks = pageBlocks[pageInd]?.row[rowIndex]?.column?.some(
            (column) => column?.blocks?.length > 0
        );

        if (hasBlocks) {
            setHandleRowRemove({ value: { rowIndex, pageInd }, state: true });
            return;
        }

        removeRowConfirm({ rowIndex, pageInd });
    };

    const pageEditorRef = useRef([]);

    const setRef = (ref, index) => {
        pageEditorRef.current[index] = ref;
    };

    const mirrorGridStyle = () => {
        return {
            display: "flex",
            // gap: `${marginValue * 2 + 20}px`,
            height: "100%",
            position: "relative"
        };
    };
    const getGridStyle = (columns) => {
        return {
            display: "grid",
            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
            gap: `${columnGap}px`,
            height: "100%",
            position: "relative"
        };
    };

    const getFlexStyle = (columnSpace = DEFAULT_ROW_COL_SPACE) => {
        return {
            display: "flex",
            gap: `${columnSpace}px`,
            height: "100%",
            position: "relative"
        };
    };

    const renderSortableItem = (
        block,
        id,
        rowIndex,
        columnIndex,
        blockIndex
    ) => (
        <SortableItem
            pageIndex={pageIndex}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            blockIndex={blockIndex}
            key={blockIndex}
            block={block}
            id={id}
            handleBlockClick={handleBlockClick}
            customEvents={customEvents}
            focusId={focusId}
            setFocusId={setFocusId}
            removeBlock={removeBlock}
            zoomLevel={zoomLevel}
            setCountImageLoaded={setCountImageLoaded}
        />
    );

    const renderDropArea = () => (
        <Box
            className="DropAreaWrap hide_on_print"
            onClick={() => setTabIndex(CONTENT)}
        >
            <Box className="DropAreaIcon">
                <Icon name="plusIcon" />
            </Box>
            <Box className="DropAreaTitle">Drag Menu Content Here</Box>
        </Box>
    );

    const renderPlacementMarker = () => (
        <Box
            className="hide_on_print placementMarker"
            sx={{
                border: "2px solid #1890FF",
                mt: 1,
                mx: 1,
                borderRadius: "2px",
                opacity: 0
            }}
        ></Box>
    );

    const renderBlockContent = (block, id) => {
        switch (block.type) {
            case BLOCK_SUB_TYPE.EDITABLE_TEXT:
            case BLOCK_SUB_TYPE.TEXT:
                return <MirrorTextBlock {...block.data} />;
            case BLOCK_SUB_TYPE.IMAGE:
                return <ImageBlock {...block.data} id={id} focusId={focusId} />;
            case BLOCK_SUB_TYPE.DIVIDER:
                return <DividerBlock {...block.data} />;
            case BLOCK_SUB_TYPE.SECTION:
                return <SectionBlock {...block.data} mirrorMode={true} />;
            default:
                return null;
        }
    };

    const renderMirrorContent = (rowBlock, rowIndex) => (
        <Box
            key={rowIndex}
            className={`ReplicatedContentWrap`}
            style={{
                padding: mirrorMode ? borderTopSpace : "",
                position: "relative"
            }}
        >
            <Box
                className="backgroundImage absoluteBlock"
                style={{
                    backgroundImage: mirrorMode ? `url(${bgImageSrc})` : "",
                    backgroundSize: mirrorMode
                        ? backgroundCover
                            ? "cover"
                            : backgroundSize + "%"
                        : "",
                    backgroundPosition: mirrorMode ? backgroundPos : "",
                    backgroundRepeat: mirrorMode ? "no-repeat" : "",
                    opacity: backgroundTrans
                }}
            ></Box>
            <Box
                className="ReplicatedContentInner"
                style={{
                    padding: mirrorMode ? borderBottomSpace : "",
                    borderStyle: mirrorMode ? borderStyle : "",
                    borderTopLeftRadius: borderTopRadius,
                    borderTopRightRadius: borderRightRadius,
                    borderBottomRightRadius: borderBottomRadius,
                    borderBottomLeftRadius: borderLeftRadius,
                    transition: mirrorMode ? "border 0.3s" : "",
                    borderColor: mirrorMode ? borderColor : "",
                    borderTopWidth: mirrorMode
                        ? borderTop
                            ? borderWidth
                            : 0
                        : "",
                    borderRightWidth: mirrorMode
                        ? borderRight
                            ? borderWidth
                            : 0
                        : "",
                    borderBottomWidth: mirrorMode
                        ? borderBottom
                            ? borderWidth
                            : 0
                        : "",
                    borderLeftWidth: mirrorMode
                        ? borderLeft
                            ? borderWidth
                            : 0
                        : "",
                    height: "100%",
                    position: "relative"
                }}
            >
                <Box
                    className={`ReplicatedContent remove_bg`}
                    style={{
                        ...getGridStyle(totalColumn),
                        backgroundColor: !showPreview ? "rgba(0,0,0,0.25)" : ""
                    }}
                >
                    {rowBlock?.column?.map((column, colIndex) => (
                        <Box
                            className={`remove_border transparent_border ${
                                showPreview ? "previewPage" : ""
                            }`}
                            key={colIndex}
                        >
                            {column?.blocks.map((block, blockIndex) => (
                                <Box key={blockIndex} className="contentBlock">
                                    {renderBlockContent(
                                        block,
                                        [
                                            pageIndex,
                                            rowIndex,
                                            colIndex,
                                            blockIndex
                                        ].toString()
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );

    const getListStyle = () => ({
        minHeight: "100%",
        maxHeight: "100%",
        overflow: gridMode ? "hidden" : "",
        position: gridMode ? "relative" : ""
    });

    const renderColumnBlocks = (column, colIndex, rowIndex, pageIndex) => (
        <Box
            ref={(ref) => setRef(ref, rowIndex, pageIndex)}
            className={`PageEditorInner remove_border remove_bg ${
                activeColIndex === [pageIndex, rowIndex, colIndex].toString()
                    ? "active"
                    : ""
            } ${showPreview ? "previewPage" : ""}`}
            key={colIndex}
            onDragEnter={(event) =>
                handleDragEnter(event, pageIndex, rowIndex, colIndex)
            }
            onDragLeave={(event) => handleDragLeave(event, colIndex)}
            onDrop={(event) => handleDrop(event, pageIndex, rowIndex, colIndex)}
            onDragOver={(event) => handleDragOver(event)}
            id={column.id}
        >
            <Droppable
                droppableId={`droppable + pageIndex-${pageIndex} + rowIndex-${rowIndex} + columnIndex-${colIndex}`}
                type="columnBlocks"
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={getListStyle(snapshot)}
                    >
                        {column.blocks.map((block, blockIndex) =>
                            renderSortableItem(
                                block,
                                [
                                    pageIndex,
                                    rowIndex,
                                    colIndex,
                                    blockIndex
                                ].toString(),
                                rowIndex,
                                colIndex,
                                blockIndex
                            )
                        )}
                        {provided.placeholder}
                        {!showPreview && renderPlacementMarker()}
                    </div>
                )}
            </Droppable>

            {column?.blocks?.length === 0 && !showPreview && renderDropArea()}
        </Box>
    );

    const resizeWidth = (
        mouseDownEvent,
        element,
        colIndex,
        rowIndex,
        pageBlockIndex
    ) => {
        const handlerWidth = element.offsetWidth;
        const startPosition = { x: mouseDownEvent.pageX };
        const totalWidth = gridRowWrap?.current?.offsetWidth;

        const minRowWidth = 5;
        const maxRowWidth = 100;
        let threshold = 0;
        let xChange = 0;

        function onMouseMove(mouseMoveEvent) {
            xChange = startPosition.x - mouseMoveEvent.pageX;
            if (gridRowWrap && gridRowWrap.current) {
                gridRowWrap.current.style.userSelect = "none";
                gridRowWrap.current.style.WebkitUserSelect = "none";
                gridRowWrap.current.style.cursor = "ew-resize";
            }

            if (xChange !== threshold) {
                element.style.right = `calc(100% - ${handlerWidth}px + ${
                    xChange / (zoomLevel / 10)
                }px)`;
                threshold = xChange;
            }
        }

        function onMouseUp() {
            setPageBlocks((prevPageBlocks) => {
                const updatedPageBlocks = [...prevPageBlocks];
                const updatedPage = { ...updatedPageBlocks[pageBlockIndex] };
                const cColWidth =
                    updatedPage?.row[rowIndex]?.column[colIndex]?.styles
                        ?.columnWidth;
                xChange = xChange / (zoomLevel / 10);
                const pixColWidth = percentToPixel(totalWidth, cColWidth);
                const newPixVal = pixColWidth + xChange; // Increment or decrement based on xChange
                const newPercVal = pixelToPercent(totalWidth, newPixVal);

                const rColWidth = newPercVal - cColWidth;

                const xChangePerc = pixelToPercent(totalWidth, xChange);
                const prevColW =
                    updatedPage.row[rowIndex].column[colIndex - 1].styles
                        .columnWidth;

                if (
                    newPercVal <= maxRowWidth &&
                    newPercVal >= minRowWidth &&
                    xChangePerc < prevColW
                ) {
                    updatedPage.row[rowIndex].column[
                        colIndex
                    ].styles.columnWidth = newPercVal;

                    // Apply changes to other rows if needed
                    const updatedPrevColW = prevColW - rColWidth;

                    updatedPage.row[rowIndex].column[
                        colIndex - 1
                    ].styles.columnWidth = updatedPrevColW;
                }

                updatedPageBlocks[pageBlockIndex] = updatedPage;
                return updatedPageBlocks;
            });

            element.style.right = `calc(100% - ${handlerWidth}px)`;
            if (gridRowWrap && gridRowWrap.current) {
                gridRowWrap.current.style.userSelect = "";
                gridRowWrap.current.style.WebkitUserSelect = "";
                gridRowWrap.current.style.cursor = "";
            }
            document.body.removeEventListener("mousemove", onMouseMove);
            document.body.removeEventListener("mouseup", onMouseUp);
        }

        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp);
    };

    const resizeHeight = (
        mouseDownEvent,
        element,
        rowIndex,
        pageBlockIndex
    ) => {
        const handlerHeight = element.offsetHeight;
        const startPosition = { y: mouseDownEvent.pageY };
        const totalHeight = gridRowWrap?.current?.offsetHeight;

        const minRowHeight = 5;
        const maxRowHeight = 100;
        let threshold = 0;
        let yChange = 0;

        function onMouseMove(mouseMoveEvent) {
            yChange = startPosition.y - mouseMoveEvent.pageY;
            if (gridRowWrap && gridRowWrap.current) {
                gridRowWrap.current.style.userSelect = "none";
                gridRowWrap.current.style.WebkitUserSelect = "none";
                gridRowWrap.current.style.cursor = "ns-resize";
            }

            if (yChange !== threshold) {
                element.style.bottom = `calc(100% - ${handlerHeight}px + ${
                    yChange / (zoomLevel / 10)
                }px)`;
                threshold = yChange;
            }
        }

        function onMouseUp() {
            setPageBlocks((prevPageBlocks) => {
                const updatedPageBlocks = [...prevPageBlocks];
                const updatedPage = { ...updatedPageBlocks[pageBlockIndex] };
                const cRowHeight = updatedPage.row[rowIndex].styles.rowHeight;
                yChange = yChange / (zoomLevel / 10);
                const pixRowHeight = percentToPixel(totalHeight, cRowHeight);
                const newPixVal = pixRowHeight + yChange; // Increment or decrement based on yChange
                const newPercVal = pixelToPercent(totalHeight, newPixVal);
                const yChangePerc = pixelToPercent(totalHeight, yChange);

                const rRowHeight = newPercVal - cRowHeight;
                const prevRowH =
                    updatedPage?.row[rowIndex - 1]?.styles?.rowHeight;

                if (
                    newPercVal <= maxRowHeight &&
                    newPercVal >= minRowHeight &&
                    yChangePerc < prevRowH
                ) {
                    updatedPage.row[rowIndex].styles.rowHeight = newPercVal;

                    const updatedPrevRowH = prevRowH - rRowHeight;

                    updatedPage.row[rowIndex - 1].styles.rowHeight =
                        updatedPrevRowH;
                }

                updatedPageBlocks[pageBlockIndex] = updatedPage;
                return updatedPageBlocks;
            });

            if (gridRowWrap && gridRowWrap.current) {
                gridRowWrap.current.style.userSelect = "";
                gridRowWrap.current.style.WebkitUserSelect = "";
                gridRowWrap.current.style.cursor = "";
            }

            element.style.bottom = `calc(100% - ${handlerHeight}px)`;
            document.body.removeEventListener("mousemove", onMouseMove);
            document.body.removeEventListener("mouseup", onMouseUp);
        }

        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp);
    };

    const renderDraggable = useDraggableInPortal();
    const getItemStyle = (snapshot, provided) => {
        const {
            draggableStyle,
            isDragging,
            translateString,
            widthPos,
            heightPos,
            leftPos,
            topPos
        } = draggedStyle(snapshot, provided, zoomLevel);

        const scale = zoomLevel / 10;

        return {
            ...draggableStyle,
            top: isDragging ? topPos : draggableStyle.top,
            left: isDragging ? leftPos : draggableStyle.left,
            width: isDragging ? widthPos : draggableStyle.width,
            height: isDragging ? heightPos : draggableStyle.height,
            backgroundColor: isDragging ? "#ffffff" : "",
            transform: isDragging
                ? `${translateString} scale(${scale})`
                : translateString
        };
    };

    const renderPageEditorInner = (rowBlock, rowIndex) => {
        const rowStyles = rowBlock?.styles;
        const rowHeight = rowStyles?.rowHeight;
        const totalHeight = gridRowWrap?.current?.offsetHeight;
        const rowHeightPix = percentToPixel(totalHeight, rowHeight);
        const columnSpace = rowStyles?.columnSpace;
        const rowPadding = `${rowStyles?.rowPadding?.top}px ${rowStyles?.rowPadding?.right}px ${rowStyles?.rowPadding?.bottom}px ${rowStyles?.rowPadding?.left}px`;
        const cRow = [pageIndex, rowIndex].toString();
        const ID = rowIndex.toString() + pageIndex.toString();

        return gridMode ? (
            <Draggable draggableId={ID} index={rowIndex} key={ID}>
                {renderDraggable((provided, snapshot) => (
                    <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={rowIndex}
                        style={{
                            ...getItemStyle(snapshot, provided),
                            ...getFlexStyle(columnSpace),
                            padding: rowPadding,
                            height: !snapshot.isDragging
                                ? rowHeight + "%"
                                : rowHeightPix + "px"
                        }}
                        tabIndex="1"
                        className="gridRowWrapper"
                    >
                        {!showPreview && rowIndex !== 0 ? (
                            <Box
                                onMouseDown={(event) =>
                                    resizeHeight(
                                        event,
                                        event.currentTarget,
                                        rowIndex,
                                        pageIndex
                                    )
                                }
                                className="hide_on_print resizeHandle"
                            ></Box>
                        ) : (
                            ""
                        )}
                        {!showPreview ? (
                            <Box
                                className={`focusableBlock hide_on_print ${
                                    focusId === cRow ? "active" : ""
                                } 
                                    `}
                                onClick={(e) => {
                                    setFocusId(
                                        [pageIndex, rowIndex].toString()
                                    );
                                    customRowEvents(
                                        pageIndex,
                                        rowIndex,
                                        "column-container"
                                    );
                                }}
                            >
                                <Box className={`editBlock`}>
                                    <Icon
                                        onClick={() => setTabIndex(STYLES)}
                                        className="editIcon"
                                        name="pencil"
                                    />
                                    <span {...provided.dragHandleProps}>
                                        <Icon
                                            className="sortIcon"
                                            name="sort"
                                        />
                                    </span>

                                    <Icon
                                        className="deleteIcon"
                                        name="deleteIcon"
                                        onClick={() =>
                                            removeRow(rowIndex, pageIndex)
                                        }
                                    />
                                </Box>
                            </Box>
                        ) : (
                            ""
                        )}
                        {rowBlock?.column?.map((column, colIndex) => (
                            <div
                                key={colIndex}
                                style={{
                                    width: `${column?.styles?.columnWidth}%`,
                                    position: "relative"
                                }}
                                className="columnWrapper"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFocusId(
                                        [pageIndex, rowIndex].toString()
                                    );
                                    customRowEvents(
                                        pageIndex,
                                        rowIndex,
                                        "column-container"
                                    );
                                }}
                            >
                                {!showPreview && colIndex !== 0 ? (
                                    <Box
                                        onMouseDown={(event) =>
                                            resizeWidth(
                                                event,
                                                event.currentTarget,
                                                colIndex,
                                                rowIndex,
                                                pageIndex
                                            )
                                        }
                                        className="hide_on_print ResizeHandle--width"
                                    ></Box>
                                ) : (
                                    ""
                                )}
                                {renderColumnBlocks(
                                    column,
                                    colIndex,
                                    rowIndex,
                                    pageIndex
                                )}
                            </div>
                        ))}
                        {provided.placeholder}
                    </Box>
                ))}
            </Draggable>
        ) : (
            rowBlock?.column?.map((column, colIndex) => {
                return (
                    <React.Fragment key={colIndex}>
                        {renderColumnBlocks(
                            column,
                            colIndex,
                            rowIndex,
                            pageIndex
                        )}
                    </React.Fragment>
                );
            })
        );
    };

    const columnEditor = useMemo(() => {
        if (mirrorMode) {
            return (
                <Box className="MirrorHtmlWrap" style={mirrorGridStyle()}>
                    <Box
                        className="MirrorHtmlInner"
                        style={{
                            height: mirrorMode ? "auto" : "",
                            padding: mirrorMode ? borderTopSpace : "",
                            position: "relative"
                        }}
                    >
                        <Box
                            className="backgroundImage absoluteBlock"
                            style={{
                                backgroundImage: mirrorMode
                                    ? `url(${bgImageSrc})`
                                    : "",
                                backgroundSize: mirrorMode
                                    ? backgroundCover
                                        ? "cover"
                                        : backgroundSize + "%"
                                    : "",
                                backgroundPosition: mirrorMode
                                    ? backgroundPos
                                    : "",
                                backgroundRepeat: mirrorMode ? "no-repeat" : "",
                                opacity: backgroundTrans
                            }}
                        ></Box>
                        <Box
                            className="MirrorHtmlBlock"
                            style={{
                                ...getGridStyle(totalColumn),
                                padding: mirrorMode ? borderBottomSpace : "",
                                borderStyle: mirrorMode ? borderStyle : "",
                                borderTopLeftRadius: borderTopRadius,
                                borderTopRightRadius: borderRightRadius,
                                borderBottomRightRadius: borderBottomRadius,
                                borderBottomLeftRadius: borderLeftRadius,
                                transition: mirrorMode ? "border 0.3s" : "",
                                borderColor: mirrorMode ? borderColor : "",
                                borderTopWidth: mirrorMode
                                    ? borderTop
                                        ? borderWidth
                                        : 0
                                    : "",
                                borderRightWidth: mirrorMode
                                    ? borderRight
                                        ? borderWidth
                                        : 0
                                    : "",
                                borderBottomWidth: mirrorMode
                                    ? borderBottom
                                        ? borderWidth
                                        : 0
                                    : "",
                                borderLeftWidth: mirrorMode
                                    ? borderLeft
                                        ? borderWidth
                                        : 0
                                    : "",
                                minHeight: "100%",
                                maxHeight: "100%",
                                position: "relative"
                            }}
                        >
                            {pageContent.map(renderPageEditorInner)}
                        </Box>
                    </Box>
                    {pageContent.map(renderMirrorContent)}
                </Box>
            );
        } else if (gridMode) {
            return (
                <Box className="GridHtmlWrap" ref={gridRowWrap}>
                    {!gridColumn && !showPreview ? (
                        <Box className="addContainerWrap hide_on_print">
                            <span
                                onClick={() => {
                                    setGridColumnPopup(true);
                                    customPageEvents(pageIndex);
                                    setPIndex(pageIndex);
                                    setFirstRowAdd(true);
                                }}
                            >
                                <Icon name="plusIcon" />
                            </span>
                            <p>Add Column Container</p>
                        </Box>
                    ) : (
                        <Droppable
                            droppableId={`rowItems + pageIndex-${pageIndex}`}
                            type="rowItems"
                        >
                            {(provided, snapshot) => (
                                <Box
                                    style={{ height: "100%" }}
                                    ref={provided.innerRef}
                                >
                                    {pageContent.map(renderPageEditorInner)}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                    )}
                </Box>
            );
        } else {
            return (
                <Box
                    style={getGridStyle(columnCount)}
                    className="singleRowWrap"
                >
                    {pageContent.map(renderPageEditorInner)}
                </Box>
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        bgImageSrc,
        columnCount,
        mirrorMode,
        gridMode,
        columnGap,
        marginValue,
        activeColIndex,
        pageBlocks,
        focusId,
        showPreview,
        zoomLevel,
        overflowContent
    ]);

    const [pageNameText, setPageNameText] = useState(
        pageBlocks[pageIndex].page_name || "Page Name"
    );

    const handleTextPaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        document.execCommand("insertText", false, text);
    };

    const [key, setKey] = useState(Date.now()); // Unique key

    const handlePageNameChange = (e) => {
        const pageBlockIndex = e.target.id;
        const newName = e.target.innerText.trim();
        const updatedName = newName === "" ? "Page Name" : newName;

        setPageNameText(updatedName);

        setPageBlocks((prevPageBlocks) => {
            // Create a copy of the array
            const newPageBlocks = [...prevPageBlocks];

            // Update the page_name of the specified index
            newPageBlocks[pageBlockIndex] = {
                ...newPageBlocks[pageBlockIndex],
                page_name: updatedName
            };

            return newPageBlocks;
        });

        // Update the key to force re-render
        setKey(Date.now());
    };

    return (
        <Root
            id={pageId}
            ref={pageEditorWrapRef}
            className={`PageEditorWrap ${showPreview ? "showPreview" : ""}`}
            style={{
                width:
                    orientation === "portrait"
                        ? `calc(${paperSize.width})`
                        : orientation === "landscape"
                        ? `calc(${paperSize.height})`
                        : ""
            }}
        >
            {!showPreview ? (
                <Box
                    ref={topBarRef}
                    className="TopBarWrapper hide_on_print flexBox justifyBetween alignCenter"
                >
                    <Box className="PageTitleWrap">
                        <p
                            key={key}
                            className="PageTitle"
                            contentEditable="true"
                            onBlur={(event) => handlePageNameChange(event)}
                            id={pageIndex}
                            onChange={(event) =>
                                setPageNameText(event.target.innerText)
                            }
                            onPaste={(e) => handleTextPaste(e)} // handle pasting content
                            dangerouslySetInnerHTML={{ __html: pageNameText }}
                        />
                        <Icon name="fileIcon" />
                    </Box>
                    {gridMode && gridColumn ? (
                        <Box className="addContainerWrap hide_on_print">
                            <Box
                                className="addContainerInner"
                                onClick={() => {
                                    setGridColumnPopup(true);
                                    customPageEvents(pageIndex);
                                    setPIndex(pageIndex);
                                    setFirstRowAdd(true);
                                }}
                            >
                                <Icon name="plusIcon" />
                                <p>Column Container</p>
                            </Box>
                        </Box>
                    ) : (
                        ""
                    )}
                    <CustomTooltip title="Edit Page Layout" placement="top">
                        <Box
                            className="PageEditIcon"
                            ref={(ref) => (pageEdit.current[pageId] = ref)}
                        >
                            <Box
                                className="PageEditIconInner flexBox alignCenter"
                                onClick={() => {
                                    setTabIndex(LAYOUT);
                                    setLayoutTabIndex(PAGE_LAYOUT);
                                    setFocusId([pageIndex].toString());
                                    customPageEvents(pageIndex);
                                    setPIndex(pageIndex);
                                    setFirstRowAdd(false);
                                }}
                            >
                                <Icon name="editIcon" />
                                <p>Page</p>
                            </Box>
                        </Box>
                    </CustomTooltip>
                </Box>
            ) : (
                ""
            )}
            <Box
                ref={(ref) => (singlePage.current[pageId] = ref)}
                className="PagesBlock remove_border"
                style={{
                    padding: !mirrorMode ? borderTopSpace : "0",
                    height:
                        orientation === "portrait"
                            ? paperSize.height
                            : orientation === "landscape"
                            ? paperSize.width
                            : "",
                    width:
                        orientation === "portrait"
                            ? paperSize.width
                            : orientation === "landscape"
                            ? paperSize.height
                            : "",
                    backgroundColor
                }}
            >
                {!mirrorMode ? (
                    <Box
                        className="backgroundImage absoluteBlock"
                        style={{
                            backgroundImage: `url(${bgImageSrc})`,
                            backgroundSize: backgroundCover
                                ? "cover"
                                : backgroundSize + "%",
                            backgroundPosition: backgroundPos,
                            backgroundRepeat: "no-repeat",
                            opacity: backgroundTrans
                        }}
                    ></Box>
                ) : (
                    ""
                )}
                {!showPreview && !gridMode ? (
                    <Box
                        className={`PageMarginBorder hide_on_print ${
                            toEdge ? "toEdge" : ""
                        }`}
                        style={{ zIndex: mirrorMode ? 1 : "" }}
                    ></Box>
                ) : (
                    ""
                )}
                {!showPreview ? (
                    <Box
                        onClick={(e) => {
                            setTabIndex(LAYOUT);
                            setLayoutTabIndex(PAGE_LAYOUT);
                            setFocusId([pageIndex].toString());
                            customPageEvents(pageIndex);
                            setPIndex(pageIndex);
                            setFirstRowAdd(false);
                        }}
                        className={`focusableBlock hide_on_print ${
                            focusId === [pageIndex].toString() ? "active" : ""
                        }`}
                        style={{ zIndex: mirrorMode ? 1 : "" }}
                    ></Box>
                ) : (
                    ""
                )}
                <Box
                    className={`PageEditor__wrap`}
                    style={{
                        padding: !mirrorMode ? borderBottomSpace : "0",
                        borderStyle: !mirrorMode ? borderStyle : "",
                        borderTopLeftRadius: borderTopRadius,
                        borderTopRightRadius: borderRightRadius,
                        borderBottomRightRadius: borderBottomRadius,
                        borderBottomLeftRadius: borderLeftRadius,
                        transition: !mirrorMode ? "border 0.3s" : "",
                        borderColor: !mirrorMode ? borderColor : "",
                        borderTopWidth: !mirrorMode
                            ? borderTop
                                ? borderWidth
                                : 0
                            : "",
                        borderRightWidth: !mirrorMode
                            ? borderRight
                                ? borderWidth
                                : 0
                            : "",
                        borderBottomWidth: !mirrorMode
                            ? borderBottom
                                ? borderWidth
                                : 0
                            : "",
                        borderLeftWidth: !mirrorMode
                            ? borderLeft
                                ? borderWidth
                                : 0
                            : "",
                        zIndex: !mirrorMode ? 1 : ""
                    }}
                >
                    {!showPreview && !mirrorMode ? (
                        <Box
                            onClick={(e) => {
                                setTabIndex(LAYOUT);
                                setLayoutTabIndex(PAGE_LAYOUT);
                                setFocusId([pageIndex].toString());
                                customPageEvents(pageIndex);
                                setPIndex(pageIndex);
                                setFirstRowAdd(false);
                            }}
                            style={{
                                height: "auto",
                                width: "auto",
                                top: borderTop ? -borderWidth : 0,
                                right: borderRight ? -borderWidth : 0,
                                bottom: borderBottom ? -borderWidth : 0,
                                left: borderLeft ? -borderWidth : 0
                            }}
                            className="absoluteBlock hide_on_print"
                        ></Box>
                    ) : (
                        ""
                    )}
                    <Box
                        style={{
                            height: "100%",
                            width: "100%",
                            border:
                                !showPreview && gridMode
                                    ? "1px dashed #939393"
                                    : "",
                            boxShadow:
                                !showPreview && gridMode
                                    ? "inset 0 0 0 2px #EAEAEA"
                                    : "",
                            overflow: gridMode ? "unset" : ""
                        }}
                        className="PageEditor remove_border"
                    >
                        {columnEditor}
                    </Box>
                </Box>
            </Box>
            {!showPreview ? (
                <Box className="pageModification hide_on_print">
                    <Icon
                        className={disabled.upward ? "disabled" : ""}
                        name="upwardArrowIcon"
                        onClick={onMoveUp}
                    />
                    <Icon
                        className={disabled.downward ? "disabled" : ""}
                        name="downwardArrowIcon"
                        onClick={onMoveDown}
                    />
                    <Icon
                        className={disabled.delete ? "disabled" : ""}
                        name="deleteIcon"
                        onClick={onDeletePage}
                    />
                    <Icon onClick={onAddPage} name="addFileIcon" />
                </Box>
            ) : (
                ""
            )}
        </Root>
    );
}
